const Keyboard = require('./vendors/keyboard.js')
const PIXI = require('pixi.js');
const Flickity = require('flickity');
const {TimelineLite, TweenLite } = require('gsap')
const MobileDetect = require('mobile-detect')
import SplitText from "./vendors/gsap-bonus/SplitText";

const md = new MobileDetect(window.navigator.userAgent);
    
// On charge la base de donnée
const optsGnl = settings.general;
const optsEvt = settings.options;

const HTML = document.getElementsByTagName('html')[0]
const URL_SERVER = 'https://love.papillonsdenuit.com';

const log = {
	info : function(msg) {
		return;
		console.info(msg);
	},
	error : function(msg) {
		console.error(msg)
		HTML.classList.add('show-error')
	}
}
function wait(delayInMS) {
  return new Promise(resolve => setTimeout(resolve, delayInMS));
}

// ---------------------
// Fallback if error
// ---------------------
let msg = document.querySelector('.message');
msg.querySelector('.btn').addEventListener('click', (e) => {
	e.preventDefault();
	document.location.reload(true);
})

class App {

  constructor() {
		let _ = this

		window.addEventListener('resize', () => {
			HTML.style.height = `${window.innerHeight}px`;
		})
		window.dispatchEvent(new Event('resize'));

		this.options = {
			selector : '.panel',
			current : -1,
			liveview : {
				width : 800,
				height : 800
			},
			video : {
				width : 1280,
				height : 720
			},
			steps : document.querySelectorAll('.panel').length
		} 

		this.onRecord = false
		this.timerPause = 0;


		this.initItw();
		
    // ---------------------
    // Create intro panel
		// ---------------------
		this.panelIntro()
		
    // ---------------------
    // Create liveview panel
		// ---------------------
		this.panelLiveview()
		
    // ---------------------
    // Create questions panel
		// ---------------------
		this.panelQuestions()
		
    // ---------------------
    // Create preview panel
		// ---------------------
		this.panelPreview()
		
    // ---------------------
    // Create email panel
		// ---------------------
		this.panelEmail()
		
    // ---------------------
    // Create email panel
		// ---------------------
		this.panelFinish()
		
    // ---------------------
    // Go on first panel
    // ---------------------
		this.goto(0);
		
    // ---------------------
    // Skip panel
		// ---------------------
		let skip = document.querySelectorAll('.trigger-next');
		if( skip ) {
			skip.forEach((el) => {
				el.addEventListener('click', (e) => {
					e.preventDefault();

					_.goto(_.options.current + 1);
				})
			})
		}
	}

	loadVideos() {
		let _ = this;
		this.questions = optsEvt.questions;

    // ---------------------
    // Load video
    // ---------------------
    this.questions.forEach((q, i) => {
    	if(q['style']['bg-file'].length > 0) {
	    	let v = document.createElement('video')
	    	// v.preload = 'auto';
	    	// v.src = q['style']['bg-file'][0]['url'];
	    	// v.load();

	    	_.questions[i]['bg-video'] = v;
    	}
    })
	}

	initItw() {
		let _ = this;

    this.loadVideos();

		_.itw = {
			id : Date.now(),
			event : optsEvt.idClient + ( optsEvt.idEvent && optsEvt.idEvent != '' ? '-' + optsEvt.idEvent : ''),
			questions : [],
			infos : [],
			intro : optsEvt.intro[0]['url'],
			outro : optsEvt.outro[0]['url'],
			song : optsEvt.loopSong[0]['url'],
			duration_intro : 0,
			duration_outro : 0,
			start_itw : 0,
			records : [],
			save : 0,
		}

		// Clear form
		let inputs = document.querySelectorAll('input');
		if( inputs ) {
			inputs.forEach((el) => {
				el.value = '';
			})
		}
	}
	
	createLiveview(elt) {
		let _ = this
		// elt.innerHTML = '';

		let link = document.querySelectorAll('a[disabled]');

		let wCanvas = this.options.liveview.width
		let hCanvas = this.options.liveview.height 
		let wCamera = this.options.video.width
		let hCamera = this.options.video.height 
		
		_.cLive = new PIXI.Application({ width: wCanvas, height: hCanvas });
		// elt.appendChild(_.cLive.view);
		
		let videoSprite = new PIXI.Sprite();
		_.cLive.stage.addChild(videoSprite);

		// let camera = document.createElement('video')
		let camera = document.querySelector('.liveview__video')
		let sliderVideo = document.querySelector('.slider__video video')
		let questionVideo = document.querySelector('.question__liveview video')

		navigator.mediaDevices.getUserMedia({
			audio : true,
			video: {
		    width: { ideal: wCamera },
		    height: { ideal: hCamera }
			}
		}).then(function(stream) {
			if( link ) {
				link.forEach((el) => {
					el.removeAttribute('disabled')
				})

				document.querySelector('.notice-required').remove();
			}

			// _.camera.src = window.URL.createObjectURL(stream)

			_.stream = stream
			camera.srcObject = _.stream
			sliderVideo.srcObject = _.stream
			questionVideo.srcObject = _.stream
			// camera.playsinline = true
			// camera.muted = true
			camera.captureStream = camera.captureStream || camera.mozCaptureStream;
			let {width, height} = stream.getTracks()[0].getSettings();

		  camera.oncanplay = function(e) {
		    // camera.play();
		  };

		  if( width != null && height != null ) {
				wCamera = width;
				hCamera = height;
				_.options.video.width = wCamera;
				_.options.video.height = hCamera;
		  }
    
			let texture = PIXI.Texture.from(camera);

			let newW = wCamera > hCamera ? wCamera * hCanvas / hCamera : wCanvas;
			let newH = wCamera > hCamera ? hCanvas : hCamera * wCanvas / wCamera;

			videoSprite.texture = texture
			videoSprite.anchor.set(0.5);
			videoSprite.scale.x = -1
			videoSprite.x = wCanvas / 2
			videoSprite.y = hCanvas / 2;
			videoSprite.width = newW;
			videoSprite.height = newH;
		});
	}

	goto(index, callback = null) {
		let _ = this
		let elts = document.querySelectorAll(this.options.selector);
		let currentElt = elts[this.options.current];
		let nextElt = elts[index];

		let panel = document.querySelectorAll('.panel')

		// Stop current video
		if( currentElt != undefined ) {
			let video = currentElt.querySelector('video')
			if( video ) video.pause();
		}

		// Play next video
		let video2 = nextElt.querySelector('video')
		if( video2 ) video2.play();

		let dir = index > this.options.current ? 1 : -1;

		if( currentElt != undefined )
			TweenLite.to(currentElt, 0.5, { x : (-100 * dir) + '%', ease : Circ.easeInOut, onComplete : function() {
				currentElt.classList.remove('active')
			} })

		TweenLite.fromTo(nextElt, 0.5, { autoAlpha : 1, x : (100 * dir) + '%'}, { x : '0%', /*delay : 0.1,*/ ease : Circ.easeInOut, onComplete : function() {
			if( typeof callback == 'function') {
				callback.call();
			}

			// Finish
			if( index == _.options.steps - 1 ) {
				_.finish()
			}

			nextElt.classList.add('active')

		} })

		// 0 = panel intro
		let sliderVideo = document.querySelector('.slider__video video')
		if( index == 0 ) sliderVideo.play();
		else sliderVideo.pause();

		// 2 = panel question
		let questionVideo = document.querySelector('.question__liveview video')
		if( index == 2 ) questionVideo.play();
		else questionVideo.pause();


		this.options.current = index;
	}

	buildTlItw(elt) {
		let _ = this;
		let recordFile = '';
		let progress = elt.querySelector('.question__progress')
		let progressRead = elt.querySelector('.question__read')
		let liveview = elt.querySelector('.question__liveview')
		let labelRec = elt.querySelector('.rec')
		let labelPause = elt.querySelector('.pause')
		
    // ---------------------
    // Prepare question
		// ---------------------
		if( /*optsEvt.nbRandom && 
				optsEvt.nbRandom >0 && 
				optsEvt.nbRandom <= this.questions.length*/
				optsEvt.activeRandom ) {

			// Sort all questions
			let questionsGroup = [];

			this.questions.forEach((q, i) => {
				if( questionsGroup[q.random] == undefined ) questionsGroup[q.random] = [];

				questionsGroup[q.random].push(q);
				// if( q.primary ) {
				// 	this.questions.splice(i, 1);
				// 	primaryQ.push(q);
				// }
			})

			// Random questions
			let questions = [];
			questionsGroup.forEach((q, i) => {
				q.sort( () => Math.random() - 0.5);
				questions.push(q[0]);
			})

			this.questions = questions


			// // Shuffle all elements
			// this.questions.sort( () => Math.random() - 0.5)

			// // Check if question is principale
			// let primaryQ = []
			// this.questions.forEach((q, i) => {
			// 	if( q.primary ) {
			// 		this.questions.splice(i, 1);
			// 		primaryQ.push(q);
			// 	}
			// })
			// this.questions = primaryQ.concat(this.questions);
			
			// // Get nb questions
			// this.questions = this.questions.slice(0, optsEvt.nbRandom);

			// // Shuffle questions final
			// this.questions.sort( () => Math.random() - 0.5)
		}

		let questions = _.questions;

		// Construct tl
		this.tlItw = new TimelineMax({ paused : true, onStart : function() {
			// recordFile = getSettings.uploads_path + path.sep + _.itw.event + '-{nb}-' +  _.itw.id;
			recordFile = _.itw.event + '-{nb}-' +  _.itw.id
		}})

		// Build panel question
		questions.forEach((question, i) => {
			let step = document.createElement('div');
			let recordedBlob = null;
			step.classList.add('question__item');
			// step.innerHTML = '<span class="counter">Question ' + (i + 1) + '/' + questions.length + '</span>' + question.content.replace(/<(.|\n)*?>/g, '');
			step.innerHTML = question.content/*.replace(/<(.|\n)*?>/g, '')*/;

			new SplitText(step.querySelector('p'), { type:"chars" })
			elt.appendChild(step);

			let progressTxt = document.createElement('div');
			progressTxt.classList.add('progress-txt');
			progressTxt.innerHTML = question.content
			step.appendChild(progressTxt);
			let split = new SplitText(progressTxt.querySelector('p'), { type:"chars" })
			// let staggerDelay = (question.times.timeRead - split.chars.length * .1) / split.chars.length
			let staggerDelay = question.times.timeRead / split.chars.length
			let qVideo = question['bg-video'];

			_.tlItw
					.to(HTML, .2, { backgroundColor : '#283377' }, `start-${i}`)
					.set(progress, { autoAlpha : 0, y : '-100%' }, `start-${i}`)
					.to(liveview, 0.3,{ autoAlpha : 0 }, `start-${i}`)
					.fromTo(step, 0.4, { autoAlpha : 0, x : '100vw'}, { autoAlpha : 1, x : '-50%', ease : Power1.easeInOut }, `start-${i}`)
					// .fromTo(progressRead, 0.4, { autoAlpha : 0, y : '50%'}, { autoAlpha : 1, y : '0%', ease : Power1.easeInOut }, `start-${i}`)
					// .fromTo(labelPause, .4, { autoAlpha : 0, x : '100%' }, { autoAlpha : 1, x : '0%', ease : Circ.easeInOut }, `start-${i}+=.4`)
					.staggerFromTo(progressTxt.querySelectorAll('div'), 0.1, { autoAlpha : 0}, { autoAlpha : 1}, staggerDelay, `start-${i}+=.4`)
					.fromTo(progressRead.querySelector('i'), question.times.timeRead, { autoAlpha : 1, width : '0%' }, { width : '100%', ease : Linear.easeNone, onStart : () => {
						//On lit la vidéo en fond si il y a du son
						// if( qVideo) qVideo.play();
					}, onComplete : () => {
						//On stop la vidéo et on remet à zero	
						// if( qVideo) {
						// 	qVideo.pause();
						// 	qVideo.currentTime = 0;
						// }
					}}, `start-${i}+=.4`)
					// .to(labelPause, 0.4, { autoAlpha : 0}, `endread-${i}`)
					.to(step, 0.4, { autoAlpha : 0}, `endread-${i}`)
					.to(progressRead, 0.4, { autoAlpha : 0 }, `endread-${i}`)
					.to(liveview, .3, {autoAlpha : 1}, `startrecord-${i}`)
					.to(HTML, .2, { backgroundColor : '#283377' }, `startrecord-${i}`)
					.fromTo(labelRec, .4, { autoAlpha : 0, x : '100%' }, { autoAlpha : 1, x : '0%', ease : Circ.easeInOut }, `startrecord-${i}`)
					.to(progress, 0.3, { autoAlpha : 1, y: '0%', ease : Power1.easeInOut }, `startrecord-${i}`)
					.fromTo(progress.querySelector('i'), question.times.timeAnswer + .2, { autoAlpha : 1, width : '0%' }, { width : '100%', ease : Linear.easeNone, onStart : function() {
						// _.tlItw.pause();
						log.info('Go question : ' + (i+1) );

						// start recording
						_.startRecording(_.stream, question.times.timeAnswer * 1000)
							.then (recordedChunks => {
								_.itw.questions[i] = question;
								_.itw.questions[i].silences = [];

						    recordedBlob = new Blob(recordedChunks, { type: "video/mp4" });
						    let filename = recordFile.replace('{nb}', i) + '.mp4'

						    _.itw.records.push({ 
						    	'filename' : filename,
						    	'blob' : recordedBlob
						    });
						    
						    log.info("Successfully recorded " + recordedBlob.size + " bytes of " +
						        recordedBlob.type + " media.");
						  })
							.catch((err) => {
								log.error('-- ERROR : Impossible start video record');
								log.error(err);
							}); 
					}, onComplete : function() {
						// _.tlItw.pause();
						TweenLite.to(progress, .3, { autoAlpha : 0 })
						// Next step 
						if( i >= questions.length - 1 ) {
							_.buildITW()
							_.goto(_.options.current + 1, () => {
								TweenLite.set(liveview, { autoAlpha : 0});
							});
						}
						// labelRec.classList.add('saving')

						// setTimeout(() => {

						// 	let filename = recordFile.replace('{nb}', i) + '.mp4';
						// 	log.info('Save video : ' + filename);

						// 	let formData = new FormData();
						// 	formData.append('video', recordedBlob)
						// 	formData.append('filename', filename)

						// 	let request = new XMLHttpRequest();
						// 	request.responseType = 'json';
						// 	request.open("POST", "./save-video.php");
						// 	request.onerror = function(e) {
						// 		_.tlItw.play();
						// 		log.error('-- ERROR : Erreur lors de l\'envoi du record')
						// 	}	
						// 	request.onload = function(e) {
						// 		_.tlItw.play();

						// 		if( request.response.statut ) {
						// 			_.itw.questions[i] = question;
						// 			_.itw.questions[i].silences = [];
						// 			_.itw.questions[i].filename = request.response.path;

						// 			log.info('PATH : ' + request.response.path);

						// 			// Next step 
						// 			if( i >= questions.length - 1 ) {
						// 				_.buildITW()
						// 				_.goto(_.options.current + 1, () => {
						// 					TweenLite.set(liveview, { autoAlpha : 0});
						// 				});
						// 			}
						// 		} else {
						// 			log.error('-- ERROR : Erreur lors de l\'enregistrement du record')
						// 		}
						// 	}
						// 	request.send(formData)
						// }, 200)

					}}/*, '+=0.1'*/)
					.set(progress, { autoAlpha : 0 }, `end-${i}`)
					.to(labelRec, 0.4, { autoAlpha : 0}, `end-${i}`)
					// .to(progress, .3, { autoAlpha : 0 }, `end-${i}`)
					.add(() => { labelRec.classList.remove('saving') }, '+=.1')
					.to(step, 0.4, { autoAlpha : 0}, `end-${i}`)
					.set(progress.querySelector('i'), { scaleX : 1, width : '0%'})
		})
	}
	
	buildITW() {
		let _ = this;
		let panel = document.querySelector('.panel.preview');
		let progress = panel.querySelector('.preview__progress')
		let loader = progress.querySelector('.video-loader')
		let container = panel.querySelector('.preview__container')
		let txtProgress = panel.querySelector('.preview__progress--txt label');

		TweenLite.to(progress, 0.3, { autoAlpha : 1})
		loader.play();

		log.info('Build interview');

	  _.itw.recordSize = {
	  	width : _.options.video.width,
	  	height : _.options.video.height
	  }

	  txtProgress.innerText = 'Envoi des vidéos'

	  /*************************
	  /*** On enregistre les records
	  /*************************/
		let formData = new FormData();

		let filenames = [];
		for(var i = 0; i < _.itw.records.length; i++) {
			filenames.push(_.itw.records[i].filename);
			
			formData.append('video_' + i, _.itw.records[i].blob);
		}
		formData.append('filenames', JSON.stringify(filenames))

		let request = new XMLHttpRequest();
		request.responseType = 'json';
		request.open("POST", "./save-video.php");
		request.onerror = function(e) {
			log.error('-- ERROR : Erreur lors de l\'envoi du record')
		}	
		request.onload = function(e) {

			if( request.response.statut ) {
				for(var i = 0; i < request.response.paths.length; i++) {
					_.itw.questions[i].filename = request.response.paths[i];
				}

			  /*************************
			  /*** On génère la vidéo
			  /*************************/

				let formData = new FormData();
				formData.append('itw', JSON.stringify(_.itw))

				let rBuild = new XMLHttpRequest();
				rBuild.responseType = 'json';
				rBuild.open("POST", "./build-itw.php", true);
				rBuild.onerror = function(e) {
					log.error('oups error');
				}
				rBuild.onload = function(e) {
					TweenLite.to(progress, 0.3, { autoAlpha : 0})
					loader.pause();
					loader.currentTime = 0;

					if( rBuild.response.statut == 1) {

						log.info('Build interview finished');
						_.itw.final = rBuild.response.final
						_.itw.duration_intro = parseFloat(rBuild.response.intro_duration) + parseFloat(_.itw.questions[0].times.timeRead);


						// Add video in preview container
						let preview = document.querySelectorAll('.finish__video')
						if( preview ) {
							preview.forEach( p => {
								p.src = _.itw.final
								p.loop = true
								// p.play()
							})
						}

						setTimeout(() => {
							_.goto(_.options.current+1)
						}, 100)
					}  else {
						log.error('-- ERROR : Erreur lors de la création de la vidéo')
					}
				}
				txtProgress.innerText = 'création vidéo en cours'
				rBuild.send(formData);

			} else {
				log.error('-- ERROR : Erreur lors de l\'enregistrement du record')
			}
		}
		request.send(formData)

		
	}

	startRecording(stream, lengthInMS) {
	  let recorder = new MediaRecorder(stream);
	  let data = [];
	 
	  recorder.ondataavailable = event => data.push(event.data);
	  recorder.start();
	  log.info(recorder.state + " for " + (lengthInMS/1000) + " seconds...");
	 
	  let stopped = new Promise((resolve, reject) => {
	    recorder.onstop = resolve;
	    recorder.onerror = event => reject(event.name);
	  });

	  let recorded = wait(lengthInMS).then(
	    () => recorder.state == "recording" && recorder.stop()
	  );
	 
	  return Promise.all([
	    stopped,
	    recorded
	  ])
	  .then(() => data);
	}


	finish() {
		let _ = this;

		let panel = document.querySelector('.panel.finish');
		let fb = panel.querySelector('.fb-btn');
		let tw = panel.querySelector('.tw-btn');
		let dl = panel.querySelector('.dl-btn');
		// let desactivate = panel.querySelector('.desactivate-video');
		let content = panel.querySelector('.content');
		let progress = panel.querySelector('.progress');

		let form = panel.querySelector('.send-insta');
		let id = form.querySelector('input[name="id_insta"]')

		let hContent = 0;
		for (var i = 0; i < content.children.length; i++) {
			hContent += content.children[i].offsetHeight
		}

		hContent = 190;
		
		_.panelQuestions();

		let formData = new FormData();
		formData.append('itw', JSON.stringify(_.itw))

		let request = new XMLHttpRequest();
		request.responseType = 'json';
		request.open("POST", "./save.php", true);
		request.onerror = function(e) {
			log.error('oups error');
		}
		request.onload = function(e) {

			if( request.response.statut == 1 || request.response.statut == 0 ) {		
				let slug = optsEvt.idClient + ( optsEvt.idEvent && optsEvt.idEvent != '' ? '/' + optsEvt.idEvent : '')
				let url = `${URL_SERVER}/videos/${request.response.date}`

				fb.href = `https://www.facebook.com/sharer.php?u=${url}`;
				tw.href = `https://twitter.com/share?url=${url}&text=Ma vidéo déclaration d'amour pour le festival @PapillonsdeNuit ❤️ Même sans festival, réalisez vous aussi votre interview vidéo 🎬&hashtags=P2Nalamaison`
				dl.href = _.itw.final;
				// desactivate.href = `mailto:hello@spik.cool?subject=[SPIK]Retirer ma vidéo&body=Merci d'enlever ma vidéo : ${url}`

				id.value = request.response.id;

				TweenLite.fromTo(content, 0.3, { autoAlpha : 0, y : '10%'}, {autoAlpha : 1, y : '0%', height : hContent })
				TweenLite.to(progress, 0.3, { autoAlpha : 0 })
			} 
		}
		request.send(formData);
	}

	/** ================================================= */
	/** ================================================= */
	/** ================================================= */
	/** ====== PANELS ======= */
	/** ================================================= */
	/** ================================================= */
	/** ================================================= */
	panelIntro() {
		let _ = this;
		let panel = document.querySelector('.panel.intro');
		let liveviewPanel = document.querySelector('.panel.liveview');
		let slider = panel.querySelector('.slider')
		let goto = panel.querySelector('.trigger-goto')

		goto.addEventListener('click', (e) => {
			e.preventDefault();
			_.goto(_.options.current+1, () => {
				liveviewPanel.dispatchEvent(new Event('click'))
			})
		})

		if( slider ) {
			var flkty = new Flickity( slider, {
				prevNextButtons: false, 
			});

			let next = slider.querySelectorAll('.trigger-slide-next')
			if( next ) {
				next.forEach((el) => {
					el.addEventListener('click', (e) => {
						e.preventDefault();
						flkty.next();
					})
				})
			}
		}
	}

	panelLiveview() {
		let _ = this;
		let liveviewPanel = document.querySelector('.panel.liveview');
		let countdown = liveviewPanel.querySelector('.liveview__countdown');
		let step = 0;
		let colors = ['#75B990', '#EC7C81', '#9ACDE2'];

		_.createLiveview(liveviewPanel.querySelector('.liveview__canvas'))
		
		// Construct live view tl
		_.tlStart = new TimelineLite({ paused : true, onComplete : function() {
			_.goto(_.options.current+1, function() {
				TweenLite.set(liveviewPanel, { clearProps : 'all' });
				step = 0;

				_.tlItw.seek(0).play();

				// Clear all props
				_.tlStart.pause().seek(0)
			});
		 }})

		 _.tlStart
		 	.to(liveviewPanel.querySelector('.txt:not(.txt-pause)'), 0.4, { autoAlpha : 0, y : '10%' }, 'start')
			.staggerFromTo(countdown.querySelectorAll('span'), .45, { y : '-60%', autoAlpha : 0}, { y : '-50%', autoAlpha : 1, onStart : () => {
				TweenLite.to(liveviewPanel, 0.45, { background : colors[step] });
				step++;
			} }, 1, 'countdown+=.2')
			.staggerTo(countdown.querySelectorAll('span'), .45, { y : '-40%', autoAlpha : 0 }, 1, 'countdown+=.65')
			.to(liveviewPanel.querySelector('.liveview__canvas'), 0.4, { autoAlpha : 0 })

		// Start the itw
		liveviewPanel.addEventListener('click', (e) => {
			e.preventDefault();
			let cTime = _.tlStart.time();
			clearTimeout(_.timerPause);
			if(cTime == 0 || cTime == _.tlStart.duration()) {
				_.tlStart.seek(0).play();
				_.itw.id = Date.now();

				// Init default infos
				_.initItw()
			}
		})
	} 

	panelQuestions() {
		let _ = this;
		let questionPanel = document.querySelector('.panel.question');

		// Clean panel
		var items = questionPanel.querySelectorAll('.question__item');
		items.forEach((el) => {
			el.parentNode.removeChild(el);
		})

		// Build itw cursus
		this.buildTlItw(questionPanel);
	}	

	panelPreview() {
		let _ = this;
		let panel = document.querySelector('.panel.preview');

		let btnRestart = panel.querySelector('.restart')
		let btnContinue = panel.querySelector('.continue')
		let container = panel.querySelector('.preview__container')

		// Restart 
		btnRestart.addEventListener('click', (e) => {
			e.preventDefault();

			// Stop video
			panel.querySelector('.preview__video video').pause();

			log.info('Start liveview');

			// Go to first panel
			_.goto(1, reinitPanel);
		})

		// Continue 
		btnContinue.addEventListener('click', (e) => {
			e.preventDefault();

			// Stop video
			panel.querySelector('.preview__video video').pause();

			// Go to first panel
			_.goto(_.options.current + 1, reinitPanel);
		})

		function reinitPanel() {
			TweenMax.set(container, { clearProps:"all" });
		}
	}

	panelEmail() {
		let _ = this;
		let panel = document.querySelector('.panel.email');

		let inputs = panel.querySelectorAll('input[name="email"]')
		let btn = panel.querySelector('.email--send');

		btn.addEventListener('click', e => {
			e.preventDefault();

			var validEmail = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
			let msg = panel.querySelector('.form__msg')

			msg.innerHTML = ''
			msg.classList.remove('show')

			inputs.forEach((elt) => {

				if ( elt.hasAttribute('required') && elt.value == '' ) {
					msg.innerHTML ='<strong>ERREUR :</strong> Veuillez renseigner tous les champs.'
					msg.classList.add('show')
					return;
				}
				else if( elt.hasAttribute('name') && elt.getAttribute('name') == 'email' && !validEmail.test(elt.value) ) {
					msg.innerHTML = '<strong>ERREUR :</strong> Veuillez renseigner un email valide.'
					msg.classList.add('show')
					return;
				}

				_.itw.infos.push({ [elt.getAttribute('name')] : elt.value});
			})

			if( msg.innerHTML == '' ) {
				// let preview = panel.querySelectorAll('.finish__video')
				// preview.pause();
				
				_.goto(_.options.current + 1)
			}
		})


    // ---------------------
    // Init keyboard
    // ---------------------
		/*new Keyboard(keyboard, {
			maxLetters : -1,
			letters : ['a','z','e','r','t','y','u','i','o','p', '<span class="back"></span>','\n','q','s','d','f','g','h','j','k','l','m', '.', '\n','w','x','c','v','b','n','@','-','_','.fr', '.com'],
			pavNum : true,
			input : panel.querySelector('input[name="email"]'),
			additionnal : ['@gmail.com', '@hotmail.com', '@yahoo.fr', '@live.com'],
			enter : panel.querySelector('.email--send'),
			callback : function() {
				var validEmail = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
				let msg = panel.querySelector('.form__msg')

				msg.innerHTML = ''
				msg.classList.remove('show')

				panel.querySelectorAll('input').forEach((elt) => {

					if ( elt.hasAttribute('required') && elt.value == '' ) {
						msg.innerHTML ='<strong>ERREUR :</strong> Veuillez renseigner tous les champs.'
						msg.classList.add('show')
						return;
					}
					else if( elt.hasAttribute('name') && elt.getAttribute('name') == 'email' && !validEmail.test(elt.value) ) {
						msg.innerHTML = '<strong>ERREUR :</strong> Veuillez renseigner un email valide.'
						msg.classList.add('show')
						return;
					}

					_.itw.infos.push({ [elt.getAttribute('name')] : elt.value});
				})

				if( msg.innerHTML == '' ) {
					_.goto(_.options.current + 1)
				}
      }
		});*/
	}

	panelFinish() {
		let _ = this;
		let panel = document.querySelector('.panel.finish');
		let insta = panel.querySelectorAll('.insta-btn');
		let content = panel.querySelector('.content');
		let progress = panel.querySelector('.progress');
		let form = panel.querySelector('.send-insta');
		let closePopup = panel.querySelector('.trigger-close-popup');
		let preview = panel.querySelector('.finish__video');

		closePopup.addEventListener('click', (e) => {
			e.preventDefault();
			document.body.dispatchEvent(new Event('click'))
		})	

		insta.forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();
				e.stopPropagation();
				HTML.classList.add('open-popup-insta')
				document.body.addEventListener('click', closePopin)
			})
		})

		function closePopin(e) {
			if( !e.target.classList.contains('popup-insta') &&
					!e.target.closest('.popup-insta') ) {
				e.preventDefault();
				HTML.classList.remove('open-popup-insta');
				document.body.removeEventListener('click', closePopin);
				let msg = panel.querySelectorAll('.msg');
				if( msg ) {
					msg.forEach((el) => {
						el.remove();
					})
				}
			}
		}

		// Send email 
		form.addEventListener('submit', (e) => {
			e.preventDefault();
			let email = form.querySelector('input[name="email_insta"]').value;
			let id = form.querySelector('input[name="id_insta"]').value;
			let text = document.createElement('div')
			text.classList.add('msg');

			if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
				text.classList.add('error');
				text.innerHTML = "L'email renseigné n'est pas valide !"
				form.appendChild(text);
				return false;
			}

			if( id == '' ) {
				text.classList.add('error');
				text.innerHTML = "Une erreur est survenue veuillez réessayé ultérieurement !"
				form.appendChild(text);
				return false;
			}

			form.classList.add('no-event');

			// request pour envoyé le mail
			let formData = new FormData();
			formData.append('email', email);
			formData.append('id', id);
			let request = new XMLHttpRequest();
			request.responseType = 'json';
			request.open("POST", "./email.php");
			request.onerror = function(e) {
				log.error(e);
				form.classList.remove('no-event');
			}
			request.onload = function(e) {
				text.innerHTML = request.response.msg;
				form.appendChild(text);
				form.classList.remove('no-event');
				form.querySelector('input[name="email_insta"]').value = '';
			}
			request.send(formData);

			return false;
		})

		panel.querySelector('.trigger-restart').addEventListener('click', (e) => {
			e.preventDefault();

			preview.pause();

			// Go to first panel
			_.goto(1, () => {
				TweenLite.set([progress, content],  { clearProps : 'all' });
				form.reset();
			});
		})
	}			
}

window.addEventListener('load', function() {
	if(typeof MediaRecorder != 'function') HTML.classList.add('app-disable')
	if( (md.mobile() || md.os() == 'AndroidOS') && !activeMobile /*&& typeof MediaRecorder != 'function'*/) {
		HTML.classList.add('is-mobile')
		return;
	} 
	new App();
})