class Keyboard {
	constructor(elt, options) {
		var _ = this;

		_.options = {
			letters : [],
			maxLetters : 4,
			pavNum: false,
			enter : null,
			backspace : null,
			additionnal : null,
			callback : function() { }
		};

    _.options = Object.assign(_.options, options);

		if( _.options.letters.length <= 0 ) {
			_.options.letters = ['a','z','e','r','t','y','u','i','o','p','\n','q','s','d','f','g','h','j','k','l','m','\n','w','x','c','v','b','n'];
		}

		_.loopKey = 0;

    var keyboard = document.createElement('div');
    keyboard.classList.add('keyboard');
    elt.appendChild(keyboard);
		var letters = document.createElement('ul');
    keyboard.appendChild(letters);
		
		// Add enter
		if( _.options.enter == null ) {
			_.options.enter = document.createElement('a');
      _.options.enter.classList.add('enter');
      elt.appendChild(_.options.enter)
		}
		_.options.enter.addEventListener('mousedown', function(e) {
			e.preventDefault();
			_.options.callback.call();
		})

		//Construct keyboard lettters
		_.constructKey(letters, _.options.letters);

		// Construct pav num
		if( _.options.pavNum ) {
      keyboard.classList.add('keyboard-w-num')
			var pavNum = document.createElement('ul')
      pavNum.classList.add('pav-num');
      keyboard.appendChild(pavNum);
			var nums = ['1','2','3','4','\n','5','6','7','\n','8','9','0'];
			
			_.constructKey(pavNum, nums)
		}

		// Add additionnal keys
		if( _.options.additionnal != null && _.options.additionnal.length > 0) {
			var additionnal = document.createElement('ul')
      additionnal.classList.add('additionnal');
      keyboard.appendChild(additionnal);
			_.constructKey(additionnal, _.options.additionnal);
		}

		// Add container to write letters
		// if( _.options.input == null ) {
		// 	_.options.input = $('<input name="text" type="text" />').prependTo(elt);
		// }

		// Add backspace
		if( _.options.backspace == null ) {
			_.options.backspace = document.createElement('a');
      _.options.backspace.classList.add('backspace'); 
      elt.appendChild(_.options.backspace);
		}
		_.options.backspace.addEventListener('mousedown', function(e) {
			e.preventDefault();
			_.backspace();
		});
	}

	constructKey(elt, keys) {
		var _ = this;

		for(var i in keys) {
			var key = keys[i];

			if( key == '\n' ) {
        let sep = document.createElement('li');
        sep.classList.add('sep')
				elt.appendChild(sep);
			} else {
        let li = document.createElement('li');
        li.innerHTML = '<a href="#"><span>'+key.trim()+'</span></a>'

				li.querySelector('a').addEventListener('mousedown', function(e) {
					e.preventDefault();

					_.keyUp(li.querySelector('a'));
				})

				elt.appendChild(li);
			}
		}
	}

	backspace() {
		let activeElement = document.activeElement
		let input = this.options.input
		if( activeElement.type != 'input') input.focus();

		var val = input.value;
		let pos = input.selectionStart;

		if( val.length > 0) {
			var front = val.substring(0, pos - 1);  
			var back = val.substring(pos, input.value.length); 

			input.value = front + back;
			input.selectionStart = input.selectionEnd = pos - 1;
			input.focus();
		}
	}

	keyUp(elt) {
		let activeElement = document.activeElement
		let input = this.options.input
		let pos = 0;

		if( activeElement.type != 'input') input.focus();
		
		let val = input.value;
		pos = input.selectionStart;

		var backRegex = /back/;
		if( backRegex.test(elt.innerHTML) ) {
			this.backspace();

			return;
		}

		if( val.length >= this.options.maxLetters && this.options.maxLetters >= 0 ) return;

		var front = val.substring(0, pos);  
		var back = val.substring(pos, input.value.length); 

		// Add new content 
		input.value = front + elt.innerText + back;
		pos = pos + elt.innerText.length;

		input.selectionStart = pos;
		input.selectionEnd = pos;
		input.focus();
	}
}

module.exports = Keyboard